import React, {useState} from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  Fade,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import "./style.css";

const ToolCards = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  return (
    <>
      <div className="pb-5">
        <Container>
          <Row>
            <Col md="3">
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-car-01.jpg")}
                  />
                
              </Card>
            </Col>
            <Col md="3">
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-truck-01.jpg")}
                  />
                
              </Card>
            </Col>
            <Col md="3">
              <Card>
                
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-suv-01.jpg")}
                  />
               
              </Card>
            </Col>
            <Col md="3">
              <Card 
              
              >
             

              
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    
                    src={require("assets/images/car-list/OVSF-truck-01.jpg")}
                  />
               
              
              </Card>
            </Col>
            <Col md="3" id="1"  onMouseEnter={() => setFadeIn(1)}
              onMouseLeave={() => setFadeIn(0)}>
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/56967e62-607b-4914-8214-69626562732f.jpg")}
                  />
                
              </Card>
              <Fade in={fadeIn===1} tag="h5" className="mt-3">
              <Button color="danger" block>Buy Now</Button>
            </Fade>
            </Col>
            <Col md="3"  onMouseEnter={() => setFadeIn(2)}
              onMouseLeave={() => setFadeIn(0)}>
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/56967e62-607b-4914-8214-69626562732f.jpg")}
                  />
                
              </Card>
              <Fade in={fadeIn===2} tag="h5" className="mt-3">
              <Button color="danger" block>Buy Now</Button>
            </Fade>
            </Col>
            <Col md="3"  onMouseEnter={() => setFadeIn(3)}
              onMouseLeave={() => setFadeIn(0)}>
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/56967e62-607b-4914-8214-69626562732f.jpg")}
                  />
                
              </Card>
              <Fade in={fadeIn===3} tag="h5" className="mt-3">
              <Button color="danger" block>Buy Now</Button>
            </Fade>
            </Col>
            <Col md="3"  onMouseEnter={() => setFadeIn(4)}
              onMouseLeave={() => setFadeIn(0)}>
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/56967e62-607b-4914-8214-69626562732f.jpg")}
                  />
                
              </Card>
              <Fade in={fadeIn===4} tag="h5" className="mt-3">
              <Button color="danger" block>Buy Now</Button>
            </Fade>
            </Col>
            <Col md="3">
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-car-01.jpg")}
                  />
                
              </Card>
            </Col>
            <Col md="3">
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-truck-01.jpg")}
                  />
                
              </Card>
            </Col>
            <Col md="3">
              <Card>
                
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-suv-01.jpg")}
                  />
               
              </Card>
            </Col>
            <Col md="3">
              <Card>
               
                  <img
                    alt="..."
                    // className="image-left-pull-right"
                    
                    src={require("assets/images/car-list/OVSF-truck-01.jpg")}
                  />
                
              </Card>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ToolCards;
