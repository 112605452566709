import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import config from "_config";

function WhyChooseUs() {
  return (
    <>
      <div className="section first-level" id="features"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(" + require("assets/images/background/auto-zone-header-bg-1680x756-alt1.jpg") + ")"
      }}
      >

          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title">Why Choose {config.short_co}</h1>
                <h5 className="description">
                  Welcome to {config.company} proudly serving
                  Atlantic Canada since 2003. Conveniently located in Lower
                  Sackville, Nova Scotia, we offer the best selection of
                  pre-owned vehicles with rates starting as low as{" "}
                  4.99%.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="ipad-container">
                  <img
                    alt="..."
                    src={require("assets/images/other-images/ipad.png")}
                  />
                </div>
              </Col>
              <Col className="offset-1" md="4">
                <div className="info info-horizontal">
                  <div className="description">
                    <h4 className="info-title">MVI</h4>
                    <p>
                      2-year Motor Vehicle Inspection on all our Pre-Owned
                      Vehicles
                    </p>
                  </div>
              
                  <div className="description">
                    <h4 className="info-title">CarFax</h4>
                    <p>CarProof and CarFax History Reports Available</p>
                  </div>
                
                  <div className="description">
                    <h4 className="info-title">Exchange</h4>
                    <p>30 Day Exchange policy</p>
                  </div>
                
                  <div className="description">
                    <h4 className="info-title">Warranty</h4>
                    <p>
                      Extended Warranties from Global, Coverage One and Lubrico
                    </p>
                  </div>
               

                
                  <div className="description">
                    <h4 className="info-title">Our Rating</h4>
                    <p>BBB A+ Rating</p>
                  </div>
                

                
                  <div className="description">
                    <h4 className="info-title">In House Lending</h4>
                    <p>In-House Financing Program</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Buy with Confidence</h2>
                <h5 className="description">
                  Family owned and operated {config.company}
                  can give our customers that personal touch that 
                  you can't find
                  elsewhere.
                </h5>
              </Col>
            </Row>
          </Container>
      
        {/* ********* END FEATURES 4 ********* */}
      </div>
    </>
  );
}

export default WhyChooseUs;
