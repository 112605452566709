import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import Rating from "react-rating";

import { LOAD_WEB_COMMENTS } from "constants/actions";

import GiveFeedback from "About/GiveFeedback";

import config from "_config";

const CommentList = ({ com }) => {
  if (com) {
    return (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar"></div>
        <CardBody>
          <h5 className="card-description">{com.description}</h5>
          <CardFooter>
            <CardTitle tag="h4">
              {com.first_name} {com.last_name}
            </CardTitle>

            <div className=" card-stars">
              <Rating
                initialRating={com.rating}
                name="rating"
                emptySymbol="fa fa-star-o fa-1.5x"
                fullSymbol="fa fa-star fa-2x"
                fractions={10}
                readonly={true}
              />
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    );
  }
};

const CustomerFeeback = () => {
  document.documentElement.classList.remove("nav-open");
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const dispatch = useDispatch();

  const [commentsList, setCommentsList] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_WEB_COMMENTS });
  }, [dispatch]);

  const { get_comments } = useSelector(
    (state) => ({
      get_comments: state.webcomments.webcomments,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_comments) {
      setCommentsList(get_comments.results);
    }
  }, [get_comments]);

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === commentsList.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? commentsList.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        className="section section-testimonials first-level"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(" +
            require("assets/images/background/auto-zone-header-bg-1680x756-alt1.jpg") +
            ")",
        }}
      >
        <Container fluid>
          <Row>
            <Col className="ml-auto mr-auto " md="8">
              <h2 className="title text-center">Customers Feedback</h2>
              <h5 className="description">
                At {config.company}, we pride ourselves on our outstanding
                customer service. We always strive to provide the highest
                quality pre-owned vehicles, as well as the best financing
                options.
              </h5>
              <h5 className="description">
                Take a moment and read some online reviews from previous
                customers as they share their professional, stress-free car
                buying experiences. Read how they made the right decision on
                where to get their next vehicle!
              </h5>
              <h5 className="description">
                Learn first-hand why many of our sales come are repeat customers
                and referrals to their friends and family. We look forward to
                winning your trust and business by putting you in your next
                pre-owned vehicle!
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="2"></Col>
            <Col md="6">
              <div className="page-carousel">
                {commentsList ? (
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                  >
                    <CarouselIndicators
                      items={commentsList}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />

                    {commentsList.map((item, key) => {
                      return (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={key}
                        >
                          <CommentList com={item} />
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="left carousel-control carousel-control-prev"
                      data-slide="prev"
                      href="#prev"
                      onClick={(e) => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="right carousel-control carousel-control-next"
                      data-slide="next"
                      href="#next"
                      onClick={(e) => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </a>
                  </Carousel>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col className="mr-auto" md="2"></Col>
          </Row>
        </Container>
        <GiveFeedback heading={"Give your feedback"} />
      </div>
    </>
  );
};

export default CustomerFeeback;
