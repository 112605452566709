import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import config from "_config";

const Team = () => {
  document.documentElement.classList.remove("nav-open");
  return (
    <>
      {/* ********* TEAM 3 ********* */}
      <div className="section first-level"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(" + require("assets/images/background/auto-zone-header-bg-1680x756-alt1.jpg") + ")"
      }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Our Team</h2>
              <h5 className="description">
                Our goal is to deliver a professional, stress-free experience to
                all of our customers.
              </h5>
              <h5 className="description">
                {config.company} Team proudly serves the Lower Sackville,
                Halifax, Dartmouth, Truro, Kentville, Wolfville, Antigonish, New
                Glasgow, Amherst, Yarmouth and throughout Nova Scotia and
                Atlantic Canada.
              </h5>
            </Col>
          </Row>
          <div className="space-top" />
          <Row>
            <Col md="6">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/images/team/pexels-photo-733872.jpeg")}
                      />
                    </CardImg>
                  </Col>
                  <Col md="7">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">Alec Thompson</CardTitle>
                      <h6 className="card-category">Owner</h6>
                      <p className="card-description">
                        
                      </p>
                      <CardFooter className="pull-left"></CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/team/adult-beautiful-blonde-blur-324658.jpg")}
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="7">
                    <CardBody className="text-left">
                      <CardTitle tag="h4">Ethan Smith</CardTitle>
                      <h6 className="card-category">Manager</h6>
                      <p className="card-description">
                      
                      </p>
                      <CardFooter className="pull-left">
                        <Button
                          className="btn-rnd "
                          color="success"
                          href="tel:9028654495"
                        >
                          Click to Call
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/team/pexels-photo-1197132.jpeg")}
                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="7">
                    <CardBody className="text-left">
                    <CardTitle tag="h4">Sophie West</CardTitle>
                      <h6 className="card-category">Sales Associate</h6>
                      <p className="card-description">
                       
                      </p>
                      <CardFooter className="pull-left">
                        <Button
                          className="btn-rnd "
                          color="success"
                          href="tel:9028654495"
                        >
                          Click to Call
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-profile card-plain">
                <Row>
                  <Col md="5">
                    <CardImg top tag="div">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/team/pexels-photo-937481.jpeg")}                        />
                      </a>
                    </CardImg>
                  </Col>
                  <Col md="7">
                    <CardBody className="text-left">
                    <CardTitle tag="h4">Lucas Andrew</CardTitle>
                      <h6 className="card-category">Sales Associate</h6>
                      <p className="card-description">
                       
                      </p>
                      <CardFooter className="pull-left">
                        <Button
                          className="btn-rnd "
                          color="success"
                          href="tel:9028654495"
                        >
                          Click to Call
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ********* END TEAM 3 ********* */}
    </>
  );
};

export default Team;
