import React from "react";

// reactstrap components
import {  Container, Row, Col } from "reactstrap";
import Calculator from "Calculator";

import ToolCards from "Home/ToolCards";
// core components
import config from "_config"
function FinancialServices() {
  document.documentElement.classList.remove("nav-open");

  return (
    <>

        <div className="section first-level" id="features"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(" + require("assets/images/background/auto-zone-header-bg-1680x756-alt1.jpg") + ")"
      }}
      >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                  <h2>Financial Services</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <div className="article-content">
                    <p>
                      Welcome to {config.company} finance
                      department. We are a dedicated, honest and reliable team
                      of professional Finance Managers. We are proud to offer
                      you an extensive list of lenders with Guaranteed Loan
                      Approvals. We are committed to making your vehicle
                      purchase affordable with 100% financing available to
                      everyone, including first -time car buyers. Refinance your
                      car loan or finance a newer one with {config.company}.
                    </p>
                    <p>
                      Get hassle-free automotive financing with {config.company}. 
                      To us, it does not matter if you have
                      had a bankruptcy or consumer proposal. We have several
                      credit auto loan companies that are standing by and
                      willing to give you a second chance on financing a
                      pre-owned vehicle in Atlantic Canada. Show you have a
                      steady income source, and you will be driving home in your
                      new automobile in no time.
                    </p>

                    <h4>
                      <strong>Why Choose Us!</strong>
                    </h4>
                    <blockquote className="blockquote text-left">
                      <p>
                        Our Financial Specialists have combined experience of
                        over 30 years of successful financing for prime and
                        sub-prime lending.
                      </p>

                      <p>
                        An impeccable track record has allowed us to build solid
                        relationships with over ten major lenders.
                      </p>
                      <p>
                        We guarantee that every person gets the best possible
                        experience and is contacted promptly by a member of our
                        team.
                      </p>
                      <p>
                        In-House Financing Program offering rates starting as
                        low as 4.99%
                      </p>
                    </blockquote>

                    <p>
                      Customers who apply online with {config.short_co} gain access to
                      flexible financing options with all the major lenders at
                      our fingertips.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="related-articles">
                  <legend />
                  <Container>
                    <Row>
                      <Col className="ml-auto mr-auto" md="10">
                        <a href="pkp">
                          <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/images/other-images/bank-logos-h.jpg")}
                          />
                        </a>
                        <p>
                          Your data is important to {config.company}. We utilize the highest SSL Technology available
                          for secure online communications to encrypt and
                          protect your financial data.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                  <h2>Calculate Your Payments</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto mb-5" md="8">
                   <Calculator />
                </Col>
              </Row>
            </Container>
          </div>

  
    </>
  );
}

export default FinancialServices;
